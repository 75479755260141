<template>
	<!-- 菜单 -->
	<div id="header" class="header phonenone">
		<div class="my-container w1900">
			<div class="leftbox">
				<div class="logo">
					<img class="img100" src="../../assets/logo/pc_logo.png" />
				</div>
				<div class="navbar_box">
					<div class="nav-item" @click="menuClick(index, item.url)" :class="curpage == item.url ? 'cur_tab' : ''"
						v-for="(item, index) in menulist" :key="item.index">
						<div class="text fontsize16">{{ item.menu }}</div>
						<div class="line"></div>
					</div>
				</div>
			</div>
			<div class="centerbox fontsize24" v-html="'河狸爱学&nbsp;单词强化训练营'"></div>
			<div class="rightbox">
				<div class="sysuserinfo" v-if="!isLogin">
					<img :src="userInfo.hlImage || require('@/assets/logo_img.png')" class="logoimg">
					<div class="usertitle fontsize20 el-dropdown-link">
						{{ userInfo.nikename || '未登录' }}
					</div>
				</div>
				<div class="sysuserinfo" v-if="isLogin">
					<img :src="userInfo.hlImage || require('@/assets/logo_img.png')" class="logoimg">
					<el-dropdown @command="handleCommand" trigger="click">
						<div class="usertitle fontsize20 el-dropdown-link">
							{{ userInfo.nikename || '未登录' }}
							<img class="dowmicon" src="../../assets/static/Frame427320794.png" />
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="mymsg">我的信息</el-dropdown-item>
							<el-dropdown-item command="outlogin">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
		<div class="my-container w1900 pcnone">
			<div class="leftbox">
				<div class="logo">
					<img class="img100" src="../../assets/logo/pc_logo.png" />
				</div>
			</div>
			<div class="centerbox fontsize24">{{bsnName}}</div>
		</div>
	</div>
</template>
<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		name: "menuBar",
		data() {
			return {
				current_index: 0, // 当前索引
				menulist: [{
						index: 0,
						menu: "首页",
						url: "home",
					},
					{
						index: 1,
						menu: "综合测评",
						url: "examPage",
					},
					{
						index: 2,
						menu: "词书大全",
						url: "allBooks",
					},
				],
				bgnum: 1, //透明度
				fontsize: "中",
			};
		},
		computed: {
			...mapState(["userInfo", "isLogin", "curpage", "sysRole","bsnName"]),
		},
		created() {
			// 此处true需要加上，不加滚动事件可能绑定不成功
			//window.addEventListener("scroll", this.handleScroll, true);
		},
		methods: {
			...mapMutations(["SET_USERINFO", "SET_ISLOGIN"]),
			//查看
			handleCommand(type){
				console.log(type)
				if(type=="outlogin"){
					this.outLogin()
				}else{
					this.$router.push({
						name:"mine",
					});
				}
			},
			//退出
			outLogin() {
				var _this = this;
				this.$confirm("确认要退出登录嘛？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						showLoading()
						_this.$http.post("app/hlexam/login/logout", {}).then(function(res) {
							hideLoading()
							//数据处理
							if (res.code == 200) {
								_this.$message.success("退出登录成功！");
								_this.SET_ISLOGIN(false);
								_this.SET_USERINFO({});
								//保存 localStorage localStorage
								localStorage.setItem('token',"")
								sessionStorage.setItem('token',"")
								localStorage.setItem('key_state',"")
								location.href = "/login"
							} else {
								_this
									.$confirm(res.message, "提示", {
										confirmButtonText: "确定",
										cancelButtonText: "取消",
										type: "warning",
									})
									.then(() => {})
									.catch(() => {});
							}
						});
					})
					.catch(() => {});
			},
			//字体大小
			handcommand2(fontsize) {
				if (fontsize == "small") {
					this.fontsize = "小";
				} else if (fontsize == "middle") {
					this.fontsize = "中";
				} else {
					this.fontsize = "大";
				}
				//基数是16，
				// $(".fontsize14").css("font-size",`${fontsize}px`)
				// $("html").setAttribute("data-size","middle")
				window.document.documentElement.setAttribute("data-size", fontsize);
			},
			//滚动
			handleScroll() {
				var scrolltop =
					document.documentElement.scrollTop || document.body.scrollTop;
				if (scrolltop < 368) {
					this.bgnum = scrolltop / 368;
				} else {
					this.bgnum = 1;
				}
			},
			menuClick(index, url) {
				//console.log("我点击了menu");
				if (url) {
					this.current_index = index; // 设置点击后的下标
					this.$emit("menuClick", url);
					this.$router.push({
						name: url,
					});
				} else {
					this.$message.info("此模块暂未开放");
				}
			},
			//处理参数
			parseString(url) {
				const obj = {};
				if (url && url.split("?").length > 1) {
					const params = url.split("?")[1].split("&");
					params.map((item) => (obj[item.split("=")[0]] = item.split("=")[1]));
				}
				console.log(obj);
				return obj;
			},
			//跳转搜索
			handleSearche(url) {
				if (!this.isLogin) {
					this.$router.push({
						path: "/login",
					});
					return;
				}
				if (url) {
					if (url.indexOf("http://") > -1 || url.indexOf("https://") > -1) {
						this.$util.windowOpen(url);
					} else {
						const newUrl = url.split("?")[0];
						const params = this.parseString(url);
						this.$router.push({
							path: "/" + newUrl,
							query: params,
						});
					}
				} else {
					console.log("无跳转路径");
					this.$message.info("此模块暂未开放");
				}
			},
		},
	};
</script>
<style></style>