import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//为了方便测试，此处用vuex做全局数据
const store = new Vuex.Store({
	state: {
		appid:"wxd0be5129de7e6b1b",
		bsnName:"河狸爱学强化训练营",
		userInfo: {},
		isLogin: false,//是否登录
		remark: '',
		curpage:"home",
		sysRole:"heli",
		//{ key: '', value: '' }
		myReadingObj:null,//我正在学习
		myBooks:[],//我的词书
		classLevel:[],//阶段
		//innerAudioContext:uni.createInnerAudioContext(),//音频
		studyingData:{
			detailwordList:[],//详情集合
			wordoptions:{},//答案选项集合
			todaywords:[],
			oriwords:[],
			curnum:0,//当前学到第几个
			firstwords:[],//第一次推送的词
			secondwords:[],//第二次的词
			thirdwords:[],//第二次的词
			failwords:[],//错误的放到这里，返回第一关
			successwords:[],//完成的反这里
			examwords:[],//单元测试的
			lessonexamobj:{
				lessonId:null,
				bookId:null,
			}
		},//新学习
		vipTpyes:{},//套餐等级
		openid:"",
	},
	mutations: {
		SET_Data(state, { key, value }) {
			// console.log(key)
			// console.log(value)
			state[key] = value
			//sessionStorage localStorage
			localStorage.setItem('key_state',JSON.stringify(state))
		},
		SET_openid(state, openid) {
			state.openid = openid
		},
		SET_vipTpyes(state, vipTpyes) {
			//console.log(vipTpyes)
			state.vipTpyes = vipTpyes
		},
		SET_CURPAGE(state, curpage) {
			//console.log(curpage)
			state.curpage = curpage
		},
		SET_REMARK(state, remark) {
			state.remark = remark
		},
		SET_USERINFO(state, userInfo) {
			// console.log(userInfo)
			state.userInfo = userInfo
		},
		SET_ISLOGIN(state, isLogin) {
			//console.log(isLogin)
			state.isLogin = isLogin
		}
	}
})

export default store
