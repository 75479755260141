import {
	Loading
} from 'element-ui';

let loadingCount = 0;
let loading;
var loadingtime = null

const startLoading = () => {
	loading = Loading.service({
		lock: true,
		text: '数据加载中……',
		background: 'rgba(0, 0, 0, 0.7)'
	});
	loadingtime = setTimeout(() => {
		clearTimeout(loadingtime)
		loading.close();
	}, 60000);
};

const endLoading = () => {
	loading.close();
};

export const showLoading = () => {
	if(loadingtime){
		clearTimeout(loadingtime)
	}
	if (loadingCount === 0) {
		startLoading();
	}
	loadingCount += 1;
};

export const hideLoading = () => {
	if(loadingtime){
		clearTimeout(loadingtime)
	}
	if (loadingCount <= 0) {
		return;
	}
	loadingCount -= 1;
	if (loadingCount === 0) {
		endLoading();
	}
};