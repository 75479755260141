<template>
	<div id="app">
		<!-- <router-view></router-view> -->
		<div class="root">
			<div class="web_main_box">
				<menuBar></menuBar>
				<div class="head_bgblue">
					<img class="img100" :src="require('@/assets/static/head_bgblue.png')" />
				</div>
				<div class="app_main">
					<keep-alive>
						<router-view v-if="$route.meta.keepAlive"></router-view>
					</keep-alive>
					<router-view v-if="!$route.meta.keepAlive"></router-view>
				</div>
				<Footer></Footer>
			</div>
		
		</div>
	</div>
</template>

<script>
import menuBar from '@/components/menuBar/menuBar.vue';
import Footer from '@/components/Footer/Footer.vue';
export default {
	components: {
		menuBar,
		Footer
	},
	mounted() {
		window.document.documentElement.setAttribute("data-size", "middle")
		//sessionStorage 
		if(localStorage.getItem("key_state")&&localStorage.getItem("token")){
			console.log("读取缓存登录")
			//存在缓存，赋值
			this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem("key_state"))))
			if(!this.$store.state.isLogin){
				localStorage.setItem("token","")
			}
		}
	},
	methods: {
		
	}
};
</script>
<style lang="scss">
.ptop {
	padding-top: 100px !important;
}
.mauto{
	margin-left: auto !important;
}
.mt20{
	margin-top: 20px !important;
}
.mb20{
	margin-bottom: 20px !important;
}
.mb12{
	margin-bottom: 12px !important;
}
.m20{
	margin-left: 20px !important;
}
.ml20{
	margin-left: 20px !important;
}
.img100{
	width: 100%;
	height: 100%;
}
.block5{
	width: 100%;
	height: 5px;
}
.block10{
	width: 100%;
	height: 10px;
}
.block20{
	width: 100%;
	height: 20px;
}
.block30{
	width: 100%;
	height: 30px;
}
.block40{
	width: 100%;
	height: 40px;
}
.block48{
	width: 100%;
	height: 48px;
}
.block58{
	width: 100%;
	height: 60px;
}
.block60{
	width: 100%;
	height: 60px;
}
.wblock10{
	width: 10px;
	min-height: 1px;
	height: 100%;
}
.wblock20{
	width: 20px;
	min-height: 1px;
	height: 100%;
}
.el-avatar{
	width: 78px !important;
	height: 78px !important;
	background: #fbfdff !important;
	border: 1px dashed #c0ccda !important;
	border-radius: 6px;
	font-size: 28px;
	color: #8c939d !important;
	line-height: 78px !important;
}
.zezhao10 {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
}
.zezhao888 {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 888;
}
.show_tc_box{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
/deep/ #end{
	display: none;
}
img{
	max-width: 100%;
	// height: 100%;
	object-fit: cover;
	display: inline-block;
	border: none;
	// filter:blur(0);
	// -webkit-filter:blur(0);
	// -moz-filter:blur(0);
	// -ms-filter:blur(0);
	// transform:translateZ(0);
	// -webkit-transform:translateZ(0);
	// -moz-transform:translateZ(0);
	// -ms-transform:translateZ(0);
	// image-rendering:-moz-crisp-edges; /* Firefox */
	// image-rendering:-o-crisp-edges; /* Opera */
	// image-rendering:-webkit-optimize-contrast; /*Webkit (non-standard naming) */
	// image-rendering:crisp-edges;
	// -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.opacity_btn{
	opacity: 0.68 !important;
}
.afterstar::after {
	content: '*';
	color: #d81e06;
}
.block45{
	width: 100%;
	height: 45px;
}
.clamp {
	lines: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
}
.clamp2 {
	lines: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}
.clamp3 {
	lines: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}
.clamp4 {
	lines: 4;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
}
.clamp5{
	lines: 5;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	overflow: hidden;
}
.clamp6{
	lines: 6;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 6;
	overflow: hidden;
}
#app {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// text-align: center;
	// color: #2c3e50;
}
.m10{
	margin-left: 10px;
}
.ql-indent-1:not(.ql-direction-rtl){
	padding-left: 0px !important;
	text-indent: 2em !important;
}
.ql-indent-2:not(.ql-direction-rtl){
	padding-left: 0px !important;
	text-indent: 4em !important;
}
.ql-indent-3:not(.ql-direction-rtl){
	padding-left: 0px !important;
	text-indent: 6em !important;
}
// ie隐藏滚动条样式代码：
 html {
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style:none;
    /*火狐下隐藏滚动条*/
    overflow:-moz-scrollbars-none;
}
/*Chrome下隐藏滚动条，溢出可以透明滚动*/
html::-webkit-scrollbar{width:0px}

// 空
.data_empty_box{
	width: 128px;
	margin: 0 auto;
	position: relative;
	z-index: 8;
	.icon{
		width: 100%;
		height: 128px;
	}
	.text{
		width: 100%;
		text-align: center;
		color: #666;
		font-size: 18px;
	}
}
</style>
