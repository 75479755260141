import netUtil from './http.js'
import axios from 'axios'

//通用
function commonFuc(url,params){
    return new Promise((resolve, reject) => {
       netUtil.post(url, params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
			}
		})
    })
}
//请求导出
function postAjaxJsonexcel(url,params){
	return new Promise((resolve, reject) => {
	    axios.defaults.timeout = 60000;///默认请求超时时间
	    var headerrel = {
	        'content-type': 'application/json'
	    }
	    headerrel["Authorization"] = sessionStorage.getItem('token')
	    axios.post(process.env.VUE_APP_BASEURL+url,params,{
	        headers: headerrel,
	        responseType: 'blob'
	    }).then(function(res) {
			resolve(res)
		})
	})
}
// 单词消词
function userSuccessWord(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userSuccessWord/save", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

// 单词消词list
function userSuccessWordlist(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userSuccessWord/saveList", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

// 添加单词到复习库或重置阶段
function userReviewWord(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userReviewWord/save", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

//保存收藏和不收藏
function userCollectBooksWord (params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userCollectBooksWord/save", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}
//错词
function userErrWord (params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userErrWord/save", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

//删除错词
function deluserErrWord (params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userErrWord/delete", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

// 删除消磁
function deletefailWord(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userSuccessWord/deleteWord", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				resolve({})
				
			}
		})
    })
}

//复习单词
function updateWordStage(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userReviewWord/updateWordStage", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

//保存学习时长
function saveStudyTime(params){
    return new Promise((resolve, reject) => {
		var miu = Math.floor(params.studyTime / 60);
		params.studyTime = miu>0?miu:1
        netUtil.post("app/word/userBooks/saveStudyTime", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				reject(false)
				
			}
		})
    })
}

//保存单元学习记录
function saveBooksLessonRecord(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userBooks/saveBooksLessonRecord", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				resolve({})
				
			}
		})
    })
}
//保存等级学习记录
function saveLevelRecord(params){
    return new Promise((resolve, reject) => {
        netUtil.post("app/word/userBooks/saveLevelRecord", params).then(function(res) {
			if(res.code == 200){
				resolve(res)
			}else{
				resolve({})
			}
		})
    })
}
// 全局化
export default {
  commonFuc,
  userCollectBooksWord,
  userSuccessWord,
  userSuccessWordlist,
  userReviewWord,
  userErrWord,
  deletefailWord,
  updateWordStage,
  saveStudyTime,
  saveBooksLessonRecord,
  saveLevelRecord,
  deluserErrWord,
  postAjaxJsonexcel
}