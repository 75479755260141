import Vue from 'vue'
import store from '../store/index.js'
import {
	Message,
	MessageBox
} from 'element-ui'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
	// {
	// path: '/',
	// name: 'index',
	// redirect: '/home',
	// component: () => import('@/views/index.vue'),
	// children: [
		
	// ]},
	{
	path: '/',
	name: 'home',
	component: () => import('@/views/home.vue'),
	meta: {
		title: '河狸爱学强化训练营',
		keepAlive: true // 需要缓存
	}},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue')
	},
	{
		path: '/agreement',//协议
		name: 'agreement',
		component: () => import('@/views/agreement.vue')
	},
	{
		path: '/aboutus',//协议
		name: 'aboutus',
		component: () => import('@/views/aboutus.vue')
	},
	{
		path: '/allBooks',//词书大全
		name: 'allBooks',
		component: () => import('@/views/allBooks.vue')
	},
	{
		path: '/studyPage',//练习
		name: 'studyPage',
		component: () => import('@/views/studyPage.vue')
	},
	{
		path: '/spellPage',//拼写
		name: 'spellPage',
		component: () => import('@/views/spellPage.vue')
	},
	{
		path: '/needPage',//错题集和
		name: 'needPage',
		component: () => import('@/views/needPage.vue')
	},
	{
		path: '/recordPage',//雪莲报告
		name: 'recordPage',
		component: () => import('@/views/recordPage.vue')
	},
	{
		path: '/recordPagePhone',//雪莲报告 手机端
		name: 'recordPagePhone',
		component: () => import('@/views/recordPagePhone.vue')
	},
	{
		path: '/mine',
		name: 'mine',
		component: () => import('@/views/mine.vue')
	},
	{
		path: '/examPage',
		name: 'examPage',
		component: () => import('@/views/examPage.vue')
	},
]
const router = new VueRouter({
	mode: 'history',
	base: "",
	routes
})

export default router
var LoginList = ["/", "/agreement", '/login'] //不需要登录的白名单
var whiteList = ["/","/allBooks","/examPage"] //菜单栏
router.beforeEach(async (to, from, next) => {
	// document.title = getPageTitle(to.meta.title)
	//console.log(to)
	if (whiteList.indexOf(to.path) > -1) {
		//console.log("SET_CURPAGE")
		store.commit('SET_CURPAGE', to.name)
	}
	if (to.matched.length > 0) {
		// next()
		if(LoginList.indexOf(to.path)<0){
			//store.state.isLogin
			////sessionStorage localStorage
			if(localStorage.getItem("token")){
				next()
			}else{
				console.log("未登录",store.state.isLogin)
				//需要登录才能访问的页面
				// next(`/login?redirect=${to.path}`)
				Message.error("请先登录账号！")
				store.commit('SET_CURPAGE', "home")
				next(`/login`)
			}
		}else{
			next()
		}
	} else {
		next('/404') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
	}
})
// 切换页面滚动回顶部
router.afterEach((to, from, next) => {
	// console.log("11322")
	window.scrollTo(0, 0);
	// 或
	// window.scroll(0, 0);
});
// 处理高版本连续跳转的错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
